import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/homepage/Map'
import StillHaveQuestions from '../components/StillHaveQuestions'
import HomeNewArrivalSlider from '../components/sliders/NewArrivalSlider'

const ThankYouContactForm = () => {
  return (
    <Layout className={'mx-auto w-full'} breadcrumbs={false}>
      <Seo title="THANK YOU" noindex={true} />
      <div className="mx-auto my-10 flex max-w-[1366px] flex-col items-center justify-center pt-2 md:flex-row">
        <div clasName="max-w-[500px] w-full">
          <StaticImage
            src="../images/thank-you-subscribe.png"
            alt="Catalog Request Thank You Banner"
            class="w-full"
          />
        </div>

        <div className="mx-auto flex flex-col gap-3 w-10/12 ">
          <h1 className="text-2xl font-bold uppercase text-red-700">
            <span className="font-black uppercase text-4xl">Thank You!</span> <br />
            For Contacting Gray & Sons Jewelers
          </h1>
          <p className="my-5 max-w-[800px] text-lg">
            Thank you for contacting us. Your message has been successfully sent. We will look over
            your inquiry and get back to you as soon as possible.
          </p>
          <h2 className="text-lg font-bold">WHAT WOULD YOU LIKE TO DO NEXT?</h2>
          <Link to="/digital-catalog/" className="button-cta max-w-max">
            BROWSE DIGITAL CATALOG
          </Link>
          <Link to="/what-s-new/" className="button-cta max-w-max">
            Continue Shopping
          </Link>
        </div>
      </div>
      <HomeNewArrivalSlider className="w-10/12" />
      <div className="bg-gray-100 py-10">
        <StillHaveQuestions />
      </div>

      <Map />
    </Layout>
  )
}

export default ThankYouContactForm
